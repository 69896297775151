export const newBundle = [
    'com.pieceClothes',
    'aizhangdian.gov.cn',
    'www.kuaizike.com',
    'com.CSHL.Draw',
    'com.zheng.VoiceGarbage',
    'com.huajiang.www',
    'com.CSHL.Calligraphy',
    'com.LocalDiary.LocalDiary',
    'com.chuangqish.diaoqianyaner',
    'com.readpacket.ke',
    'com.huaiqangu.youshihuahuaqiangu',
    'com.rui.jinmeirui',
    'com.shuo.liulishuo',
    'cn.haa.fonfApp',
    'com.cooperationcircle.CooperationCircle',
    'com.LD-landscape',
    'com.jackyugames.piraterunaway',
    'com.jackyugames.piraterunawa',
    'com.sofamall.www',
    'com.jr.pianyiju',
    'com.guanjia.qianyan',
    'com.tc.killer',
    'com.game.FSaveToHang',
    'com.ccc.SweetDream12',
    'com.lemonCoder.OASIstore',
    'com.find.answerColor',
    'com.Xiang.GMStore',
    'com.Sheng.DZPStore',
    'com.JQCZ.JQCZGrowQuietly',
    'ceom.masd.FcsrTextWire',
    'aue.uavehicle',
    'com.Hong.CYStore',
    'cn.tao.TCApp',
    'com.xiangcunshu.com',
    'com.wang.xianhua',
    'com.shangzezhushou.cuteBabyProject',
    'com.AntigueProject.fandieAntique',
    'Ceramic.CeramicProduct',
    'com.sing.setitevey',
    'com.wang.xianhua',
    'com.cwz.riddle',
    'com.qinghuachuju',
    'oubsk38.chujianbaitai',
    'Forest.cake.com',
    'com.HuaKaGo',
    'tuogao7364qjnup.xiaopangwenwan'
]